
import { GetUserGiveawayResponse } from '@/plugins/api';
import { Component, Vue } from 'vue-property-decorator';
import GoToArchive from '../components/GoToArchive.vue'
import GoToNewGiveaway from '../components/GoToNewGiveaway.vue'
import GiveawayDetail from '../components/GiveawayDetail.vue'

@Component({
  components: {
    GoToArchive,
    GoToNewGiveaway,
    GiveawayDetail
  },
})
export default class ActiveGiveawayPage extends Vue {
  giveaways: GetUserGiveawayResponse[] = [];
  async getGiveaways (): Promise<void>{
    try {
      const res = await this.$api.getMyGiveaways()
      this.giveaways = res.data;
    } catch (err) {
      console.log(err);
    }
  }

  mounted (): void{
    this.getGiveaways()
  }
}
